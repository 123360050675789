// src/pages/Home.js
import React from 'react';

import './../assets/styles/HomePage.css'

function HomePage() {
    return (

        <div className="home-container">
            <section className="section" id="section-one">
                <h2>Welcome To KOITECH</h2>
            </section>
            <section className="section" id="section-two">
                <h2>Our Vision</h2>
                <p>Our vision is to empower PhD students by transforming the academic job search landscape, creating a seamless platform that connects talented scholars with universities seeking innovation and expertise. We aim to foster a vibrant academic community where aspiring researchers can find fulfilling positions that match their skills and aspirations, ultimately enhancing the pursuit of knowledge and advancing research worldwide</p>
            </section>
            <section className="section" id="section-three">
                <h2>Our Values</h2>
                <ul>
                    <li><strong>Empowerment:</strong> We empower PhD students by providing the tools and resources they need to navigate their career paths confidently.</li>
                    <li><strong>Innovation:</strong> We embrace creativity and innovation in our approach to connecting students with universities, constantly improving our platform and services.</li>
                    <li><strong>Integrity:</strong> We operate with transparency and honesty, ensuring that our processes are fair and trustworthy for all users.</li>
                    <li><strong>Collaboration:</strong> We believe in the power of collaboration—between students, universities, and our team—to achieve shared goals and foster a supportive academic community.</li>
                    <li><strong>Diversity and Inclusion:</strong> We value diverse perspectives and strive to create an inclusive environment where all PhD students feel represented and respected.</li>
                    <li><strong>Excellence:</strong> We are committed to delivering high-quality services and support, striving for excellence in everything we do.</li>
                    <li><strong>Lifelong Learning:</strong> We promote a culture of continuous learning and growth, encouraging both our team and our users to pursue knowledge and personal development.</li>
                </ul>
            </section>
        </div>
    );
}

export default HomePage;

