import React from 'react';

function AboutPage() {
    return (

         <div className="home-container">
            <section className="section" id="section-one">
                <h2>About Us</h2>
                <p>At KOITECH, we are a team united by our passion for technology, particularly the transformative potential of AI in enhancing the health and wellness of society.</p>
            </section>
         </div>
    );
}

export default AboutPage;
