// src/components/Logo.js
import React from 'react';
import logo from '../../assets/logo.png'; // Adjust the path as needed

const Logo = () => {
  return (
    <img src={logo} alt="Logo" style={{ height: '150px', width: '250px' }} />
  );
};

export default Logo;
