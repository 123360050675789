import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';

function Header() {
    return (
        <header className="header">
            <Logo></Logo>
            <nav>
                <Link to="/">Home</Link> 
                <Link to="/product">Products</Link>
                <Link to="/about">About</Link>
                <Link to="/contact">Contact</Link> 
            </nav>
        </header>
    );
}

export default Header;
