import React from 'react';

function Footer() {
    return (
        <footer className="footer">
            <div>� 2024 KOITECH. All rights reserved.</div>
            <nav>
                <a href="#">Privacy Policy</a>
                <a href="#">Terms of Use</a>
            </nav>
        </footer>
    );
}

export default Footer;
