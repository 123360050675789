// src/pages/Product.js
import React from 'react';

function ProductPage() {
    return (
        <div>
            <h1>Welcome To KOITECH</h1>
            <p></p>
        </div>
    );
}

export default ProductPage;

